@import '../../../../../../../shared/utils/variable.scss';

@mixin container($content) {
	display: flex;
	justify-content: $content;
	align-items: center;
	width: 100%;
	color: #ffffff;
}

.pointsTrackerContainer {
	width: 50%;
}

.containerPoints {
	@include container(space-between);
	padding: calc(5%) calc(10%);
	gap: 12px;
	height: 100%;

	.ProgressBarContainer {
		display: flex;
		flex-direction: column;
		width: 50%;
		height: 100%;

		@media screen and (max-width: $TABLET_BREAKPOINT) {
			justify-content: space-between;
			width: 100%;
		}
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: unset;
		.firstButton {
			display: none;
		}
		.secondButton {
			display: block;
		}

		.labelTitle {
			text-align: center;
			width: 100%;
		}

		.labelSubtitle {
			text-align: center;
			width: 100%;
		}

		gap: 100px;
		padding-right: calc(5%);
		padding-left: calc(5%);
		flex-direction: column;
	}
}

.containerWithoutPoints {
	@include container(center);
	flex-direction: column;
	padding: calc(5%) calc(20%);
	text-align: center;

	@media (max-width: $TABLET_BREAKPOINT) {
		height: 90%;
		padding: 0 calc(5%);

		.planVisitButtonWithoutPoints {
			width: 100%;
		}

		.labelSubTitle {
			width: 100%;
		}
	}
}

.labelTitle {
	font-size: 24px;
	padding-bottom: 16px;
	font-family: 'Geomanist-bold', sans-serif;
}

.labelSubtitle {
	font-size: 14px;
}

.planVisitButton {
	font-family: 'Geomanist-bold', sans-serif;
	padding: 16px 90px;
	font-size: 14px;
	margin-top: 32px;
	height: 48px;
	border: none;
	background-color: #ab6503;
	color: white;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (max-width: $DESKTOP_SMALL_BREAKPOINT) {
		padding: 16px 24px;
	}
}

.planVisitButtonWithoutPoints {
	font-family: 'Geomanist-bold', sans-serif;
	padding: calc(16px) 97px;
	margin-top: 32px;
	border: none;
	background-color: #ab6503;
	color: white;
	cursor: pointer;
}

.secondButton {
	display: none;
}
