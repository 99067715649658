@import '../../../shared/utils/variable.scss';

@mixin geomanist-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 24px;
	line-height: 32.51px;
	vertical-align: middle;
}

@mixin geomanist-font-mobile {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 16px;
	line-height: 21.67px;
	vertical-align: middle;
}

.progress-bar-container {
	font-family: 'Geomanist-bold', sans-serif;
	display: flex;
	flex-direction: column;
	position: relative;
}

.circular-chart {
	display: block;
	width: 100%;
	height: 100%;
}

.circle {
	fill: none;
	stroke-linecap: round;
	transform-origin: 100% 100%;
	transition: stroke-dashoffset 0.8s linear;
}

.progressBarLimits {
	display: flex;
	justify-content: space-between;
	padding: 0 0.75em 0 1em;
}

.title {
	font-size: 1.5em;
	text-align: center;
	padding-bottom: 16px;
}

.svg {
	align-items: center;
}

.commentaryText {
	@include geomanist-font();

	@media screen and (max-width: $DESKTOP_SMALL_BREAKPOINT) {
		@include geomanist-font-mobile();
	}
	text-align: center;
	padding-top: 1em;
	font-size: 1.5em;
}

.pointsCounter {
	text-align: center;
	line-height: 1;
	position: absolute;
	bottom: 0;
	top: 45%;
	right: 0;
	left: 0;

	@media screen and (max-width: $DESKTOP_SMALL_BREAKPOINT){
		top: 37.5%;
	}
}

.pointsValue {
	font-size: 2.625em;

	@media (max-width: 824px) and (max-height: 655px) {
		font-size: 1.625em;
	}
}
.pointsText {
	font-size: 1.25em;

	@media (max-width: 824px) and (max-height: 655px) {
		font-size: 0.5em;
	}
}
