@import '../../../../../../../shared/utils/variable.scss';

@mixin open-sans-font {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16.34px;
	text-align: center;
	vertical-align: middle;
}

@mixin geomanist-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 24px;
	line-height: 32.51px;
	text-align: center;
	vertical-align: middle;
}

.header {
	@include geomanist-font();
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	background-color: var(--secondary-color, #38322c);

	@media (max-width: $TABLET_BREAKPOINT) {
		background-color: transparent;
		height: 65px;
		padding: 0;
	}

	.icon {
		color: #ffffff;
		width: 24px;
		height: 24px;

		@media (max-width: $TABLET_BREAKPOINT) {
			display: none;
		}
	}

	.title {
		flex-grow: 1;
	}
}

.flex-container {
	display: flex;
	justify-content: center;
	padding: 24px 7px 24px 7px;

	@media (max-width: $TABLET_BREAKPOINT) {
		padding: 0px 8px 24px 8px;
		width: 100dvw;
	}
}

.cards-container {
	width: 100%;

	@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
		display: grid;
		grid-template-columns: repeat(4, 243px);
		justify-content: center;
		align-items: center;
		justify-items: center;
		gap: 24px;
		@media (max-width: 1365px) {
			grid-template-columns: repeat(2, 240px);
		}
		@media (max-width: 845px) {
			grid-template-columns: repeat(1, 240px);
		}
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		display: flex;
		flex-direction: column;
		gap: 0px;
	}

	> div:hover {
		.logo {
			transform: scale(1.1);
		}
	}
}

.links {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	padding: 0px 34px;
	gap: 16px;

	@media (max-width: $TABLET_BREAKPOINT) {
		flex-direction: row;
		width: 100%;
		padding: 0px 27px;
	}

	.link {
		@include open-sans-font();
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		border: 1px solid #ffffff;
		padding: 16px;
		background: transparent;
		transition: transform 0.2s;
		text-decoration: none;

		&:hover {
			transform: scale(1.1);
		}
	}
}

.logo {
	transition: transform 0.2s;

	&.outback {
		width: 100dvw;
		max-width: 132px;
		max-height: 50px;
		@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
			max-width: 160px;
			max-height: 60.6px;
			margin: 0px 42px;
		}
	}

	&.carrabbas {
		width: 100dvw;
		max-width: 207px;
		@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
			max-width: 211.75px;
			max-height: 45px;
			margin: 0px 16px;
		}
	}

	&.bonefish {
		width: 100dvw;
		max-width: 106px;
		max-height: 73px;
		@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
			margin: 0px 69px;
		}
	}

	&.flemings {
		width: 100dvw;
		max-width: 196px;
		max-height: 50px;
		@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
			max-width: 211.75px;
			max-height: 60px;
			margin: 0px 16px;
		}
	}
}
