@import '../../../../../shared/utils/variable.scss';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0px;
	border-radius: 8px;

	@media (min-width: ($TABLET_BREAKPOINT + 1px)) {
		max-width: 240px;
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		flex-grow: 1;
		border-radius: 0px;
		gap: 24px !important;
	}

	&.outback {
		gap: 44.5px;
		background-size: cover;
		background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-obs-desktop.webp');

		@media (max-width: $TABLET_BREAKPOINT) {
			background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-obs-mobile.webp');
		}
	}

	&.carrabbas {
		gap: 56px;
		background-size: cover;
		background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-cig-desktop.webp');

		@media (max-width: $TABLET_BREAKPOINT) {
			background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-cig-mobile.webp');
		}
	}

	&.bonefish {
		gap: 32px;
		background-size: cover;
		background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-bfg-desktop.webp');

		@media (max-width: $TABLET_BREAKPOINT) {
			background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-bfg-mobile.webp');
		}
	}

	&.flemings {
		gap: 45px;
		background-size: cover;
		background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-fle-desktop.webp');

		@media (max-width: $TABLET_BREAKPOINT) {
			background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/card-fle-mobile.webp');
		}
	}
}