@import '../../../shared/utils/variable.scss';

.logged-container {
	@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
		position: relative;
		background-color: #000000;
		z-index: 1;
		padding: 0 60px 32px 60px;
	}
	.background {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-size: cover;
		opacity: 0.45;
	}
}

.logged-title {
	margin: auto;
	padding: 56px 0 32px 0;
	text-align: center;
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 24px;
	line-height: 32.51px;
	color: #ffffff;
	@media (max-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.container {
	display: flex;
	max-width: 1000px;
	margin: auto;
	font-family: 'Open Sans', sans-serif;
	@media (max-width: $TABLET_BREAKPOINT) {
		flex-direction: column-reverse;
	}
}

.text-section {
	height: 100%;
	width: 60%;
	background: #fff;
	color: #333333;
	@media (max-width: $TABLET_BREAKPOINT) {
		height: auto;
		width: 100%;
	}
}

.text-content {
	width: 90%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 64px;
	color: #333333;
	@media (max-width: $DESKTOP_SMALL_BREAKPOINT) {
		width: 100%;
		padding: 54px 24px;
	}
	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 16px;
	}

	.title {
		font-family: 'Geomanist-bold', sans-serif;
		font-size: 32px;
		color: #333333;
		h1 {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 12px;
			margin-bottom: 16px;
			letter-spacing: 2px;
		}
	}
	.border {
		border-bottom: 3px solid #333333;
		width: 40px;
	}
	:global(.list) {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-left: 18px;
		color: #333333;
		:global(.border) {
			border-bottom: 1px solid #ededed;
			width: 90%;
			list-style: none;
		}
		li {
			font-size: 16px;
			line-height: 22px;
			font-weight: 400;
			:first-child {
				font-weight: 700;
			}
		}
	}
	.message {
		font-size: 14px;
		background: var(--background-color);
		padding: 0px 16px;
		border-radius: 4px;
		span {
			font-weight: 700;
		}
	}
	.join-now {
		height: 49px;
		padding: 16px;
		border: none;
		background-color: #ab6503;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		color: #fff;
		&:hover {
			filter: brightness(95%);
			cursor: pointer;
		}
	}
}

.img-content {
	width: 40%;
	background: var(--background-color);
	padding: 0px 16px;
	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	@media (max-width: $TABLET_BREAKPOINT) {
		width: 100%;
		height: 162px;
		position: relative;
		.image {
			position: absolute;
			right: 8px;
			min-width: 225px;
			width: 225px;
			height: auto;
		}
	}
}
