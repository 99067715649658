@import '../../../../../../../shared/utils/variable.scss';

@mixin center-flex($direction) {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: $direction;
}

@mixin text-format($size, $lineHeigth, $weight) {
	color: var(--tertiary-dark, #333333);
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	line-height: $lineHeigth;
	font-weight: $weight;
}

@mixin text-format-geomanist($size, $lineHeigth) {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: $size;
	line-height: $lineHeigth;
}

.container {
	margin: 32px auto 0;
	width: 100%;
	background: #fff;
}

.header {
	background-color: #f9f9f5;
	height: 100px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.image {
		position: absolute;
		left: 0;
		margin: 0 30px;
		width: 24px;
		height: 24px;
	}

	.mobileTitle {
		text-align: center;
		font-size: 24px;
	}

	h1 {
		font-weight: bold;
		@include text-format-geomanist(32px, 43px);
		line-height: 43.34px;
	}

	p {
		@include text-format(12px, 19px, 400);
		padding: 0 24px;
	}
}

.content {
	background: #fff;
}

.loadMoreButtonArea {
	padding: 15px 0;
	@include text-format(14px, 19px, 700);
	color: #ab6503;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	p {
		margin-right: 10px;
	}

	.icon-area {
		width: 14px;
		height: 8px;
	}
}

.loadMoreButtonArea:hover {
	color: #a74c08;
	cursor: pointer;
}
