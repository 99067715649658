.container {
	video {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		z-index: 1;
	}
	
	video::-webkit-media-controls {
	  display: none !important;
	}
	
	video::-webkit-media-controls-start-playback-button {
	  display: none !important;
	}

	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(34, 34, 34, 0.6);
		z-index: 2;
	}
}
